import get from 'lodash/get';
import WidgetWrapper from '../../wrapper/WidgetWrapper';
import dom from '../../wrapper/DomWrapper';

import { subscribeToDeviceChanging } from '../../observer/deviceObserver';
import { getDeviceType } from '../../helpers/browser';

import { getDeviceRatio } from '../../../device';
import adjustToDevice from '../../../components/Instagram/utils';
import { DEVICE_TYPES, GRID_SIZE } from '../../../components/Instagram/constants';
import { INSTAGRAM_IMAGE_CLASS_NAME, INSTAGRAM_IMAGE_TEMPLATE } from './constants';
import defaultImage from '../Image/utils';

class Instagram extends WidgetWrapper {
  init = () => {
    this.elInstagram = dom.getCollection(this.selector);

    if (!this.elInstagram.length) return;

    this.elInstagram.forEach(async (instagram) => {
      const settings = get(instagram, 'dataset.settings', null);

      if (!settings) return;

      await this.renderPosts(instagram, JSON.parse(settings));

      subscribeToDeviceChanging(instagram, this.onResize(instagram));
    });
  };

  instagramMedia = async (href, element, retry = 0) => {
    try {
      const response = await fetch(href);
      const text = await response.text();

      element.removeAttribute('style');
      // eslint-disable-next-line no-param-reassign
      element.nextSibling.style.display = 'none';

      const parsePage = (txt) => {
        const domParser = new DOMParser();
        return domParser.parseFromString(txt, 'text/html');
      };
      const doc = parsePage(text);
      return this.extractPostsFromInstaPage(doc);
    } catch (e) {
      if (retry === 5) return null;

      const media = await this.instagramMedia(href, element, retry + 1);

      return media;
    }
  };

  extractPostsFromInstaPage = (page) => {
    const elScripts = Array.from(dom.getCollection('script', page));
    const sharedDataRawText = elScripts.filter(
      (item) => item.textContent.indexOf('_sharedData') > -1,
    )[0];

    if (!sharedDataRawText) {
      // eslint-disable-next-line no-console
      console.warn('Failed to parse instagram profile');
      return [];
    }

    const sharedDataJSONText = sharedDataRawText.textContent
      .trim()
      .match(/= (.*);/)[1];
    let sharedData;

    try {
      sharedData = JSON.parse(sharedDataJSONText);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Failed to parse instagram profile', err);
    }

    if (!sharedData) {
      // eslint-disable-next-line no-console
      console.warn('Failed to parse instagram shareData');
      return [];
    }
    const postsNodes = get(
      sharedData,
      'entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges',
    );

    if (!Array.isArray(postsNodes)) {
      // eslint-disable-next-line no-console
      console.warn('Failed to parse instagram post edges');
      return [];
    }

    return postsNodes.map((i) => i.node);
  };

  prepareImages = (images = [], amount = 1) => images
    .slice(0, amount)
    .map(({
      id, thumbnail_src: src, shortcode: code, ...img
    }) => ({
      id,
      code,
      src,
      caption: get(img, 'edge_media_to_caption.edges.0.node.text'),
    }));

  renderPosts = async (element, settings) => {
    const {
      href,
      amount,
      targetBlank,
      className,
      showCaption,
      empty,
    } = settings;

    const media = await this.instagramMedia(href, element);

    if (!media) return;

    const preparedImages = this.prepareImages(media, amount);

    if (!preparedImages.length) {
      dom.hide(element);

      const elErrorWrap = element.nextSibling;
      elErrorWrap.removeAttribute('style');
      dom.addText(elErrorWrap.firstChild, empty.text);
      dom.addText(elErrorWrap.lastChild, empty.sub);
      return;
    }

    preparedImages.forEach((image) => {
      const itemSize = this.getImageSizeForDevice(element, getDeviceType());
      const item = this.createImageElement(
        image,
        className,
        showCaption,
        targetBlank,
      );
      element.appendChild(item);
      dom.updateStyle(item, itemSize);
    });
  };

  createImageElement = (
    image,
    className = '',
    shouldShowCaption = false,
    shouldOpenInNewTab = false,
  ) => {
    if (!image) return null;

    const { caption, src, code } = image;
    const elItem = dom.createElement('div');

    dom.addClass(elItem, INSTAGRAM_IMAGE_CLASS_NAME);
    dom.addHtml(elItem, INSTAGRAM_IMAGE_TEMPLATE(
      code,
      src,
      caption,
      shouldOpenInNewTab,
      shouldShowCaption,
      className,
    ));

    const elImg = dom.getElement('img', elItem);
    const elErrorWrap = dom.getElement('._error-wrap', elItem);

    elImg.onerror = () => {
      elImg.src = defaultImage;
      dom.addClass(elErrorWrap, 'picture-wrap_unavailable');
      dom.addClass(elErrorWrap, 'picture-wrap_unavailable_hide');
    };

    return elItem;
  };

  resize = (widget, deviceType) => {
    const imageSize = this.getImageSizeForDevice(widget, deviceType);
    const elImages = dom.getCollection(`.${INSTAGRAM_IMAGE_CLASS_NAME}`, widget);

    elImages.forEach((image) => dom.updateStyle(image, imageSize));
  };

  getImageSizeForDevice = (widget, deviceType) => {
    const { settings = {} } = widget.dataset;
    const {
      itemSize = GRID_SIZE,
      amountInRow = 3,
    } = JSON.parse(settings);

    const adjustSize = adjustToDevice(itemSize, deviceType);
    const deviceRatio = getDeviceRatio(deviceType, DEVICE_TYPES);

    const count = Math.ceil((adjustSize / GRID_SIZE) * amountInRow * deviceRatio);

    return { width: `${100 / count}%` };
  };

  onResize = (widget) => (deviceType) => this.resize(widget, deviceType);
}

export default Instagram;
